import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  VideoPlayer,
  Actions
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Eye from '../../assets/images/icons/eye.svg';
import Money from '../../assets/images/icons/money.svg';
import Website from '../../assets/images/icons/website.svg';
import Flexible from '../../assets/images/icons/flexible.svg';
import Touch from '../../assets/images/icons/touch.svg';
import RewardB from '../../assets/images/icons/reward-b.svg';

const SmartContracts = () => {
  return (
    <PageLayout>
      <SEO
        title="Smart contracts"
        description="Smart contracts enable automation and efficiency between systems and entities"
      />
      <Layout dark background="blue-dark">
        <Hero type="code">
          <div className="caption">Smart Contracts</div>
          <Title color="white">
            Smart contracts enable automation and efficiency between systems and entities
          </Title>
          <Description color="blue-light">
            Connect your smart contracts, which can be written in any programming language, to real world
            business data. Envoke complex inputs and outputs based on your connections to data, events, and
            payments.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Website,
                title: 'Self-executed and self-enforced',
                body:
                  'Streamline operations, reduce redundancy, facilitate, verify, or enforce the negotiation and performance of any contract.',
              },
              {
                Icon: Flexible,
                title: 'Trust and automation',
                body:
                  'Smart contracts reduce unnecessary costs, time expenditure, and inefficiencies in processes, while also increasing transparency.',
              },
              {
                Icon: Money,
                title: 'Consistency and security',
                body:
                  'Keep different databases run by different companies on different systems in a state of consistency.',
              },
              {
                Icon: Eye,
                title: 'Connections',
                body:
                  'Connect off chain data, webhooks, oracles, and APIs to your smart contracts. Envoke actions to payment systems, applications, and external systems or blockchains.',
              },
              {
                Icon: RewardB,
                title: 'OpenFaas and Docker',
                body:
                  'Simplify the deployment of smart contracts with OpenFaas and Docker, allowing developers to program smart contracts in any coding language.',
              },
              {
                Icon: Touch,
                title: 'Update and maintain smart contracts',
                body:
                  'Dragonchain is unique among blockchain platforms in that it treats smart contracts as software that can be maintained.',
              },
            ]}
          />
          <Row auto span={12} gap="large">
            <Column>
              <Section caption="Oracles" title="Integrate data feeds and oracles with smart contracts">
                <p>
                  Connect external oracles with your smart contracts to selectively ledger any data on your
                  own blockchain. Software engineers have full freedom to select the data points. A cron
                  schedule envokes at a fixed time interval and creates transactions for you. Transactions can
                  be pulled to see all the information on your own blockchain. Specific data can selectively
                  be made public in any front-end interface, or envoke actions in external applications
                  connected to your smart contracts and blockchain system.
                </p>
                <br />
                <p>
                  Depending on the use case, for example in finance with large liabilities betweens payment
                  systems or in healthcare with test results, a company might want to leverage decentralized
                  oracles. This gives more certainty compared to using one single centralized oracle, as this
                  can be a single point of failure. By using two, three or more oracles with the same data
                  points, actions can be envoked when the majority of oracles reached consensus on the
                  accuracy of the data.
                </p>
              </Section>
            </Column>
            {/* TODO: this needs to be in a column */}
            <VideoPlayer
              url="https://www.youtube.com/watch?v=sKInjF3x_sM"
              caption="Cron scheduling to contact an oracle and ledger data"
            />
          </Row>
          <Row gap="large" /> {/* TODO: remove after video player fix */}
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="Which programming languages for smart contracts are supported by Dragonchain?">
                  Any executable code, anything you can write, can be written into a smart contract. This
                  flexibility is what allows us to say that developers with no blockchain experience can use
                  any code language to access the benefits of blockchain. Customers have used NodeJS, Python,
                  Java, and even BASH shell script to write smart contracts on Dragonchain. With Docker
                  containers, we achieve better separation of concerns, faster deployment, higher reliability,
                  and lower response times. We chose Kubernetes for its self-healing features, ability to run
                  multiple services on one server, and its large and thriving development community. It is
                  resilient, scalable, and automated. OpenFaaS allows us to package smart contracts as Docker
                  images for easy deployment. Contract deployment time is now bounded only by the size of the
                  Docker image being deployed but remains fast even for reasonably large images. We also take
                  advantage of Docker’s flexibility and its ability to support any language that can run on
                  x86 architecture. Any image, public or private, can be run as a smart contract using
                  Dragonchain.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={7}>
              <Section title="Explore the benefits of smart contracts.">
                83% of Executives are looking for blockchain solutions to the most pressing issues facing
                their businesses. Let Dragonchain show you the private and secure, scalable and cost effective
                solutions and services we have ready for you today.
              </Section>
            </Column>
            <Column right middle span={5}>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default SmartContracts;
